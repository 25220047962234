/*
 * MAIN STYLES ENTRY FILE
 */

// Third Party
// ToDo: leftovers to replace with npm call
//require('../Styles/src/ThirdParty/_fancybox.scss');
//require('@fancyapps/fancybox/dist/jquery.fancybox.css');
//require('../Styles/src/ThirdParty/_slick.scss');
//require('../Styles/src/ThirdParty/_slick-theme.scss');
//require('../Styles/src/_reset.scss');

//// Main Styles 
require('../Styles/src/_animations.scss');
require('../Styles/src/_global.scss');
require('../Styles/src/_navigation.scss');
require('../Styles/src/_home.scss');
require('../Styles/src/_responsive.scss');
require('../Styles/src/_print.scss');

// Overrides + Fixes 
require('../Styles/src/_sf-editor-overrides.scss');
//require('../Styles/src/_ie-edge-hacks.scss');