////////////////////////////////////////////////////////////////////////////////////
// MODULE IMPORTS:
////////////////////////////////////////////////////////////////////////////////////

import Parallax from 'parallax-js';
var popup = require("jquery-popup-overlay");
//import quicklink from "quicklink/dist/quicklink.js";

console.log("working");
$();

////////////////////////////////////////////////////////////////////////////////////
// PAGE-PRELOADING WITH QUICKLINK (Just trying this out)
// https://github.com/GoogleChromeLabs/quicklink
////////////////////////////////////////////////////////////////////////////////////

//window.quicklink = require('quicklink');
//quicklink.listen();

//const urlsToPrefetch = new Set([]);

//// loop through all hyperlinks inside nav elements and add the url to the set of prefetch Urls
//$("nav a").each(function () {
//    var url = this.href;
//    if (url !== "" && this.protocol.indexOf("http") >= 0 ) {
//        urlsToPrefetch.add(url);
//    }
//    //console.log(urlsToPrefetch);
//});
//quicklink.prefetch(urlsToPrefetch).catch(err => {
//    // Handle own errors
//});

////////////////////////////////////////////////////////////////////////////////////
// POLYFILLS
////////////////////////////////////////////////////////////////////////////////////

// Focus without scrolllng (not working)
//require("./polyfills/focus-options-polyfill.js");

////////////////////////////////////////////////////////////////////////////////////
// GLOBAL VARS:
////////////////////////////////////////////////////////////////////////////////////

var ww; // window width
var wh; // window width
var hh;
var selectedClassName = 'selected'; // the selected class name for menu items
var menuOpenClassName = 'open'; // the class that defines the open state of the menu
var fadeInSpeed = 50; // the fade in transition speed
var fadeOutSpeed = 75; // the fade out transition speed
var navMouseOutDelay = 600; // the delay before the nav disappears on mouseout
var mouseOutTimer; // the MouseOut timeout timer 
var is_responsive = false;
var $header = $("header");
var st; // scroll top
var lastScrollTop = 0; // used for tracking scroll position and direction
var scrollDirection = "down"; // default

function refreshScrollData() {
  // Gets the scrollTop and direction and updates the global variables accordingly
  st = window.pageYOffset || document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    scrollDirection = "down";
  } else {
    scrollDirection = "up";
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}
refreshScrollData();
function refreshDimensions() {
  // update calculated dimensions (to be used on resize events, or anytime a manual refresh is required)
  ww = $(window).width();
  wh = $(window).height();
  hh = $("header").outerHeight();

  // ### Check if we are in Mobile (ie Responsive mode)
  if ($('.mobileHeader').css('display') === 'block') {
    is_responsive = true;
  } else {
    is_responsive = false;
  }

  // padding to match fixed header height
  //if ($header && $header.length) {
  //    $("#wrapper > main").css({ marginTop: "calc(" + $header.height() + "px - 2rem)" }); // header height minus the difference in height upon animation
  //}

  refreshScrollData(); // refresh scroll data just in case...
}
function updateHeaderScrollClasses() {
  // Scroll-to-top link
  if (lastScrollTop > 450) {
    //$('.scrollToTop').fadeIn();
    $('.scrollToTop').addClass("visible");
  } else {
    //$('.scrollToTop').fadeOut();
    $('.scrollToTop').removeClass("visible");
  }

  // Hide / show the header when scrolling up
  $header.removeClass("down up").addClass(scrollDirection);
  if (lastScrollTop === 0) {
    $header.addClass("top");
  } else {
    $header.removeClass("top");
  }
}
updateHeaderScrollClasses();

////////////////////////////////////////////////////////////////////////////////////
// MAIN DOCUMENT READY
////////////////////////////////////////////////////////////////////////////////////

$(document).ready(function () {
  $header = $("#wrapper > header");
  refreshDimensions();
  $(window).trigger('resize');
});

// Play Audio 
window.addEventListener('DOMContentLoaded', function () {
  $(document).on("click", ".audioFile a", function () {
    playAudio($(this).attr("data-audioid"));
    return false;
  }).on("keypress", function (e) {
    if (e.which === 13) {
      // enter key code
      playAudio($(this).attr("data-audioid"));
      return false;
    }
  });

  // Play Audio
  function playAudio($audioid) {
    var audioFile = $('#' + $audioid).addClass("playing").get(0).play();
  }
});

////////////////////////////////////////////////////////////////////////////////////
// STICKY HEADER
////////////////////////////////////////////////////////////////////////////////////

//$(document).ready(function () {
//    var sticky = new Waypoint.Sticky({
//        element: $('.header')[0]
//    });
//});

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/// SMOOTH PAGE TRANSITIONS / LOADING SPINNERS
//////////////////////////////////////////////////////////////////////////////////////////////////////////

// Page loading spinner animation
if (isSitefinityEditor()) {
  $("body").addClass("loaded");
}
window.destinationUrl = ""; // explicit global declaration

$(document).ready(function () {
  // Add a "loaded" class when the document is finshed loading
  $("body").addClass("loaded");
  $("body").removeClass("loading");

  // Revert to initial state
  //window.onbeforeunload = function (event) {
  //    console.log("unload event", event);
  //    if (!isExternal(destinationUrl)) {
  //        $("body").addClass("unloading");
  //        $("body").removeClass("loaded");
  //    }
  //};

  //window.onpageshow = function (event) {
  //    if (event.persisted) {
  //        //window.location.reload();
  //        // Add a "loaded" class when the document is finshed loading
  //        $("body").addClass("loaded");
  //        $("body").removeClass("loading");
  //    }
  //};
});

// store the destination url to be used for page loading animation logic
$(document).on("click", "a[href]", function (event) {
  destinationUrl = $(this).attr("href");
});

////////////////////////////////////////////////////////////////////////////////////
// SCROLL EVENTS
////////////////////////////////////////////////////////////////////////////////////

$(window).on("scroll", function () {
  $header = $("#wrapper > header");
  refreshScrollData();
  updateHeaderScrollClasses();
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/// SMOOTH SCROLL 
//////////////////////////////////////////////////////////////////////////////////////////////////////////

// SMOOTH SCROLL (IN-PAGE ANCHOR LINKS)
$(document).on("click", 'a[href*="#"]:not([href="#0"]):not([href="#"])', function (event) {
  // On-page anchor links
  if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
    // Figure out which element to scroll to
    var $target = $(this.hash);
    $target = $target.length ? $target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if ($target.length) {
      // Only prevent default if animation is actually gonna happen
      event.preventDefault();
      smoothScrollTo($target);
    }
  }
});

// SCROLL TO LOCATION ON PAGE
function smoothScrollTo($target) {
  if (!$target.length) {
    return;
  }
  $('html, body').animate({
    scrollTop: $target.offset().top - $("header").outerHeight() - 20
  }, 400, 'swing', function () {
    $target.focusWithoutScrolling();
    if ($target.is(":focus")) {
      // Checking if the target was focused
      return false;
    } else {
      $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
      $target.focusWithoutScrolling(); // Set focus again
    }

    // open accordion if this content is inside
    var $parentAccordion = $target.parents(".accordion");
    if ($parentAccordion.length) {
      expandAccordion($parentAccordion);
    }
  });
}

// Scroll to anchor links properly on page load (accounting for sticky header)
if (!isSitefinityEditor()) {
  if (window.location.hash) {
    smoothScrollTo($(window.location.hash));
  }
  $(document).ready(function () {
    if (window.location.hash) {
      smoothScrollTo($(window.location.hash));
    }
  });
}

// Focusing usually scrolls the page automatically - this prevents that.
$.fn.focusWithoutScrolling = function () {
  $(this).focus(e => {
    e.preventDefault();
    e.target.focus({
      preventScroll: true
    });
  });
};

// ###### Scroll to Tab Content
// ### Click event for the "Scroll to Tab" for TabStrip (works for all RadTabs)
$(document).on("click", ".RadTabStrip a.rtsLink", function () {
  if (is_responsive) {
    scrollToAnchorTab();
  }
});

// ### "Scroll to Tab Content function
function scrollToAnchorTab() {
  var aTag = $(".tabContainer"); // need to add this class "tabContainer" on any manually added RadMultiPage
  $('html,body').animate({
    scrollTop: aTag.offset().top
  }, 'slow');
}

// ###### Scroll to Top
// ### Check to see if the window is top if not then display "Scroll to Top" button
// ### Click event for the "Scroll to Top" button
$(document).on('click', '.scrollToTop', function () {
  $('html, body').animate({
    scrollTop: 0
  }, 800);
  return false;
});

////////////////////////////////////////////////////////////////////////////////////
// WINDOW RESIZING
////////////////////////////////////////////////////////////////////////////////////

$(window).on('resize', function () {
  refreshDimensions();
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////
///SEARCH BOX FUNCTIONALITY
//////////////////////////////////////////////////////////////////////////////////////////////////////////

//$(function () {
//    var searchWrapper = $('.sfsearchBox');
//    var searchInput = searchWrapper.find('input[name="search"]');
//    var searchIndex = searchWrapper.find('input[id="hdnSearchIndex"]');
//    var searchLocation = searchWrapper.find('input[id="hdnSearchLocation"]');
//    var searchButton = searchWrapper.find('button');

//    searchButton.click(function (e) {
//        e.preventDefault();

//        var index = searchIndex.val();
//        var value = searchInput.val().trim();
//        var location = searchLocation.val().trim();
//        if (index && value !== '' && location !== '') {
//            window.location = location
//                + '?indexCatalogue='
//                + index + '&searchQuery='
//                + value + '&wordsMode=0';
//        }
//    });

//    searchInput.keypress(function (e) {
//        var key = e.which;

//        // the enter key code
//        if (key === 13) {
//            searchButton.click();
//            return false;
//        }
//    });
//});

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/// LAZY   http://jquery.eisbehr.de/lazy/
//////////////////////////////////////////////////////////////////////////////////////////////////////////
$('.lazy').lazy({
  threshold: 0 // images will load when 100 px from viewport edge
});

// Needed for lazy loading in some sliders to load images on click/swipe of next slide
function scrollUpDown1px() {
  setTimeout(function () {
    $(window).scrollTop($(window).scrollTop() - 1);
    $(window).scrollTop($(window).scrollTop() + 1);
  }, 250);
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/// FORMS / AUTO-FILL
//////////////////////////////////////////////////////////////////////////////////////////////////////////

// SEARCH BOX - PLACEHOLDER TEXT
$('.search input[type="text"]').attr("placeholder", "How Can We Help You?");

// Fix for Chrome Form Autofill for our contact form
$(".contactForm input[name*=Name]").attr('autocomplete', 'name');
$(".contactForm input[name*=Email]").attr('autocomplete', 'email');
$(".contactForm input[name*=Phone]").attr('autocomplete', 'tel');
// Or you could turn it off
//$('form').attr('autocomplete', 'off');

//////////////////////////////////////////////////////////////////////////////////////////////////////////
/// Resize Items based on the content inside them. Useful for sliders so that items remain same height
/// called like in window resize event: resizeItemsVertically($('.servicesList'), $('.servicesList .items .item .bottom'));
//////////////////////////////////////////////////////////////////////////////////////////////////////////

function resizeItemsVertically(baseElement, itemHeightElement) {
  if (baseElement.length > 0) {
    // check for element
    var itemsMaxHeight = 0;
    itemHeightElement.each(function () {
      var thisItemHeight = $(this).height();
      if (thisItemHeight > itemsMaxHeight) {
        itemsMaxHeight = thisItemHeight;
      }
    });
    itemHeightElement.height(itemsMaxHeight);
  }
}

// Clear Text Selection
function clearSelection() {
  if (document.selection) {
    document.selection.empty();
  } else if (window.getSelection) {
    window.getSelection().removeAllRanges();
  }
}

// Email link warning message
$(document).ready(function () {
  $('a[href^="mailto"]').on("click", function () {
    return confirm("Sending an e-mail to us will not make us your lawyers.  Please do not include any confidential information in your e-mail.");
  });
});

//////////////////////////////////////////////////////
/// Accordions
//////////////////////////////////////////////////////

var groupClass = "group";
var expandedClass = "active";
$(document).ready(function () {
  var $accordions = $(".accordion"); //.not(".disabled");
  if ($accordions.length && !isSitefinityEditor()) {
    // Add accessibility attributes
    updateAccordionAccessibilityAtts();

    // Add expand/collapse all button to first accordion
    //$accordions = $(".accordion");
    //if ($accordions.length > 1) {
    //    $("<div class='btnExpandAll expand'>Expand All</div>").prependTo($accordions.first());
    //    $accordions.first().addClass("hasExpandAll");
    //}
    // ToDo: Find a better way of adding the expand/collapse all button without making the <dl> markup invalid

    // Expand first 
    if ($(".accordion." + expandedClass).length) {
      expandAccordion($(".accordion." + expandedClass).first());
    }

    // Open all accordions with the "active" class
    $(".accordion.active").each(function () {
      var $accordion = $(this);
      expandAccordion($accordion);
    });

    // Click & Keypress handlers
    $(".accordion .accordionTop").on("click", function (ev) {
      //if ($(this).is(".disabled")) {
      //    return false;
      //}
      toggleAccordion($(this).parent(".accordion"));
      return false;
    }).on("keypress", function (ev) {
      if (ev.which === 13) {
        // enter key code
        //if ($(this).is(".disabled")) {
        //    return false;
        //}
        toggleAccordion($(this).parent(".accordion"));
        return false;
      }
    });

    // Expand / Collapse All accordions
    $(".btnExpandAll").on("click", function () {
      //debugger;
      var $button = $(this);
      if ($button.hasClass("expand")) {
        $accordions.each(function () {
          expandAccordion($(this)); // expand each accordion
        });
        updateExpandAllButton();
      } else {
        $accordions.each(function () {
          collapseAccordion($(this)); // close each accordion
        });
        updateExpandAllButton();
      }
    });
  }
});
function updateAccordionAccessibilityAtts() {
  // Make sure they're tab navigable/focusable
  $(".accordion").each(function (i, el) {
    var $accordion = $(el);
    var $accordionTop = $accordion.find(".accordionTop");
    var $accordionBottom = $accordion.find(".accordionBottom");
    if ($accordionTop.length && $accordionBottom.length) {
      // make sure accordionBottoms have unique Ids
      var accordionBottomId = $accordionBottom.uniqueId().attr("id"); // ToDo: get this

      // make accodions focusable
      $accordionTop.attr("tabindex", "0");
      var accordionTitle = $accordionTop.text().trim();
      if (!$accordionTop[0].hasAttribute("aria-label") && $accordionTop.attr("aria-label") !== "") {
        $accordionTop.attr("aria-label", accordionTitle);
        $accordionTop.attr("aria-controls", accordionBottomId);
        if ($accordionTop.is(".active")) {
          $accordionTop.attr("aria-expanded", "true");
          $accordionBottom.attr("aria-hidden", "false");
        } else {
          $accordionTop.attr("aria-expanded", "false");
          $accordionBottom.attr("aria-hidden", "true");
        }
      }
    }
  });
}

// Update the expand button to reflect current state of accordions
function updateExpandAllButton() {
  var $button = $(".btnExpandAll");
  var $accordions = $(".accordion");
  var $activeAccordions = $accordions.filter(".active");
  if ($button.length && $accordions.length > $activeAccordions.length) {
    // if some are open (but not all) then set to expand
    $button.addClass("expand");
    $button.text("Expand All");
  } else {
    $button.removeClass("expand");
    $button.text("Collapse All");
  }
}

// Toggle the target accordion
function toggleAccordion($accordion) {
  if ($accordion.hasClass(expandedClass)) {
    collapseAccordion($accordion);
  } else {
    closeGroupMembers($accordion); // close group members first
    expandAccordion($accordion);
  }
  updateExpandAllButton();
}

// Expand single accordion (and upate group)
function expandAccordion($accordion) {
  $accordion.addClass(expandedClass);
  $accordion.find(".accordionTop").addClass(expandedClass);
  $accordion.find(".accordionTop").attr("aria-expanded", "true");
  $accordion.find(".accordionBottom").addClass(expandedClass).slideDown();
  $accordion.find(".accordionBottom").attr("aria-hidden", "false");
  updateExpandAllButton();
}

// Close single accordion (and upate group)
function collapseAccordion($accordion) {
  $accordion.removeClass(expandedClass);
  $accordion.find(".accordionTop").removeClass(expandedClass);
  $accordion.find(".accordionTop").attr("aria-expanded", "false");
  $accordion.find(".accordionBottom").removeClass(expandedClass).slideUp();
  $accordion.find(".accordionBottom").attr("aria-hidden", "true");
  updateExpandAllButton();
}

// Check if accordion in group. If so, update group members
function closeGroupMembers($accordion) {
  var classes = $accordion[0].className.trim().split(/ |\t|\|/); // get array of classes (regex to account for space, tab, and pipe separators)
  var groups = $.grep(classes, function (classes, i) {
    // get only the classes containing groupClass
    return classes.indexOf(groupClass) === 0;
  });
  $.each(groups, function (i, group) {
    $(".accordion." + group).each(function () {
      collapseAccordion($(this)); // close each grouped accordion
    });
  });
}

// accordianUL 
$(document).ready(function () {
  var groupClass = "group";
  var expandedClass = "active";
  $(".accordionUL span.toggle").on("click", function () {
    toggleAccordion($(this).parent());
    return false;
  }).on("keypress", function (e) {
    if (e.which === 13) {
      // enter key code
      toggleAccordion($(this).parent());
      return false;
    }
  });

  // Toggle the target accordion
  function toggleAccordion($accordion) {
    if ($accordion.hasClass(expandedClass)) {
      collapseAccordion($accordion);
    } else {
      expandAccordion($accordion);
    }
  }
  function expandAccordion($accordion) {
    $(".accordionUL li." + expandedClass + ">ul").slideUp();
    $(".accordionUL li").removeClass(expandedClass);
    $accordion.addClass(expandedClass).children('ul').slideDown();
  }

  // Close single accordion (and update group)
  function collapseAccordion($accordion) {
    $(".accordionUL li").removeClass(expandedClass);
    $accordion.children('ul').slideUp();
  }
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////
// Misc
//////////////////////////////////////////////////////////////////////////////////////////////////////////

// Check if sitefinity page editor is open
function isSitefinityEditor() {
  return $("body").hasClass("sfPageEditor");
}

// Check if a link element has 
function isRelativeUrl(url) {
  var regex = new RegExp('^(?:[a-z]+:)?//', 'i');
  return regex.test(url);
}
function isExternal(url) {
  var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
  if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== location.protocol) return true;
  if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":(" + {
    "http:": 80,
    "https:": 443
  }[location.protocol] + ")?$"), "") !== location.host) return true;
  return false;
}

// Clickable items (e.g. a clickable article card with nested links)
$(document).on("click", ".clickable", function (event) {
  var $this = $(this);

  // If there's not data-url, then find the first child el with an href and use that
  var itemUrl = "";
  var target = "_self"; // default

  // First check for data atts. If none, use the first child link
  if (typeof $this.data("url") !== 'undefined' && $this.data("url").length) {
    itemUrl = $this.data("url");
    if (typeof $this.data("target") !== 'undefined' && $this.data("target").length) {
      target = $this.data("target");
    }
  } else {
    var $firstLinkFound = $this.find("a[href]").not("[href^=tel]").not("[href^=mailto]").first();
    itemUrl = $firstLinkFound.attr("href");
    target = $firstLinkFound.attr("target") ? $firstLinkFound.attr("target") : target;
  }
  if (itemUrl) {
    window.open(itemUrl, target);
  }
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////
// Event hook 
//////////////////////////////////////////////////////////////////////////////////////////////////////////

window.scriptBottom = window.scriptBottom || [];
window.scriptBottom.push(function () {});
$(document).ready(function () {
  // loop through the queued functions and run them
  if (scriptBottom.length) {
    for (var i = 0; i < scriptBottom.length; i++) {
      try {
        scriptBottom[i]();
      } catch (error) {
        console.error(error);
      }
    }
  }
});

// Wrap link text with a span to be styled. This allows custom content link styles that don't underline images or icons
if (!isSitefinityEditor()) {
  var $contentLinks = $(".cmsContent a, .sf-Long-text a");
  $contentLinks.has("img").addClass("contentImageLink");
  $contentLinks.has(".fa,.far,.fal,.fab,.fad,.fas,.fak").each(function () {
    var $link = $(this);
    var $contents = $link.contents();
    var linkTextWrapper = "<span class='linkText'></span>";
    $link.addClass("contentIconLink");
    var $textNodes = $contents.filter(function () {
      return this.nodeType === 3;
    }).wrap(linkTextWrapper);
    var supportedNestedTags = ["address", "strong", "bold", "em", "i", "small", "sub", "sup"];
    $contents.filter(supportedNestedTags.join(",")).wrapInner(linkTextWrapper);
  });
}

////////////////////////////////////////////////////
//$(document).on("click", "a", function(ev, el) {
//    var $link = $(el);
//    var a = $link[0];
//    var url = $link.attr("href");
//    if (url) {

//    }

//    //if ($link.) {

//    //}

//});

//$("a[href*=#]").each(function() {

//    var $link = $(this);
//    var a = $link[0];
//    var hash = $link.attr("href");

//    // if the anchor is somewhere on this page, then remove the
//    if (a.pathname === window.location.pathname) {
//        $link.attr("href", "#" + hash);
//    }

//});

//$(".secondaryContent > nav").stick_in_parent();

// History Timeline Filters
window.addEventListener('DOMContentLoaded', function () {
  var $filters = $('.timelinePointTypes [data-filter]'),
    $filterItems = $('.filterable [data-pointtype]');
  $filters.on('click', function (e) {
    e.preventDefault();
    var $this = $(this);
    $filters.removeClass('active');
    $this.addClass('active');
    var $filterPointType = $this.attr('data-filter');
    if ($filterPointType === 'all') {
      $filterItems.removeClass('is-selected').fadeOut().promise().done(function () {
        $filterItems.addClass('is-selected').fadeIn();
      });
    } else {
      $filterItems.removeClass('is-selected').fadeOut().promise().done(function () {
        $filterItems.filter('[data-pointtype*= "' + $filterPointType + '"]').addClass('is-selected').fadeIn();
      });
    }
  });
});