/*
 * MAIN SCRIPTS ENTRY FILE
 */

// Dependencies
require('slick-carousel');
require('jquery-lazy');
require('jquery-backstretch');
require("jquery-ui/ui/tabbable.js"); // imports a ":focusable" selector from jQuery UI (we obviously don't want to )
require("jquery-ui/ui/unique-id.js"); // imports a function for generating unique ids (useful for accessibility when auto-ID'ing things for aria-controls labels)
require("waypoints/lib/jquery.waypoints.js"); // ToDo: revisit. it makes sense that we need the jquery build ... but not sure if the resolve alias is required in webpack.config.
//require("waypoints/lib/shortcuts/sticky.js");
require('@fancyapps/fancybox/dist/jquery.fancybox.js');
require('jquery.cookie');

//require('sticky-kit/dist/sticky-kit.js');

// Old bundle files which have not been changes to npm dependencies:
//require("./src/ThirdParty/svg-inject.js");
//require("./src/ThirdParty/jquery.fancybox.min.js"); // installed but not imported yet -- need to figure out CSS. For now still using this local copy

// MAIN SCRIPTS
require("./src/master.js");
require("./src/mobile-menu.js");

// Main Styles (moved here after Yarn 2 and Webpack 5 upgrade)
require("../Styles/styles.js");